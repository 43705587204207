<template>
    <title-bar :title="$t('navigations.acTypes')"/>
    <resource-listing :resource="$AcTypes" base-url="ac-types" add-button="messages.acTypeCreate"
                      delete-message="messages.acTypeDeleted" :emails="false"/>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import ResourceListing from '../../Settings/components/ResourceListing.vue'

export default {
    components: {
        TitleBar,
        ResourceListing
    },
}
</script>
